import React from 'react';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Works from './Works/Works';

const WorksPage = () => {
    return (
        // <Layout>
        <>
        <SEO title="Spin　D&D　|　Works" />
            <Works />
        </>
        // </Layout>
    );
};
export default WorksPage